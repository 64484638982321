import type { CollectionEntry } from 'astro:content';
import { siteMetadata } from '@lib/config';
import { isDateBefore } from '@akashrajpurohit/utils';

export const difference = <T>(arr1: T[], arr2: T[]) =>
	arr1.filter((x) => !arr2.includes(x));

export const intersection = <T>(arr: T[], ...args: T[][]): T[] =>
	arr.filter((item: T) => args.every((arr: T[]) => arr.includes(item)));

export const isNewsletterPublished = (publishDate: Date) => {
	const todaysDate = new Date();
	return isDateBefore(publishDate, todaysDate);
};

export const getRandomPostTitle = (posts: CollectionEntry<'blog'>[]) => {
	const randomIndex = Math.floor(Math.random() * posts.length);
	const randomPost = posts[randomIndex];
	return randomPost.data.title;
};

export const getPublishedAndSortedPosts = (
	allPosts: CollectionEntry<'blog'>[],
	{ includeSponsoredPosts = false, includeCanonicalPosts = false } = {},
) => {
	return allPosts
		.filter((post) => {
			if (import.meta.env.PROD) {
				return post.data.published;
			}

			return siteMetadata.devMode.showDraftPosts ? true : post.data.published;
		})
		.filter((post) => {
			if (import.meta.env.PROD) {
				return post.data.sponsored ? includeSponsoredPosts : true;
			}

			return post.data.sponsored
				? siteMetadata.devMode.showSponsoredPosts || includeSponsoredPosts
				: true;
		})
		.filter((post) => {
			// If the post is sponsored and the validTill date is crossed today's date, then don't show the post.
			if (import.meta.env.PROD) {
				if (
					post.data.sponsored &&
					post.data.validTill &&
					includeSponsoredPosts
				) {
					return isDateBefore(new Date(), post.data.validTill);
				}
			}

			if (post.data.sponsored && post.data.validTill) {
				return (
					isDateBefore(new Date(), post.data.validTill) ||
					siteMetadata.devMode.showSponsoredPosts ||
					includeSponsoredPosts
				);
			}

			return true;
		})
		.filter((post) => {
			if (includeCanonicalPosts) {
				return true;
			}

			return post.data.canonicalUrl === undefined;
		})
		.sort((post1, post2) => {
			if (isDateBefore(post1.data.date, post2.data.date)) {
				return 1;
			}

			return -1;
		});
};

export const getPublishedAndSortedNewsletters = (
	allNewsletters: CollectionEntry<'newsletter'>[],
) => {
	return allNewsletters
		.filter((newsletter) => {
			if (import.meta.env.PROD) {
				return isNewsletterPublished(newsletter.data.publishDate);
			}

			return siteMetadata.devMode.showDraftNewsletters
				? true
				: isNewsletterPublished(newsletter.data.publishDate);
		})
		.sort((newsletter1, newsletter2) => {
			if (
				isDateBefore(newsletter1.data.publishDate, newsletter2.data.publishDate)
			) {
				return 1;
			}

			return -1;
		});
};

export const getSortedSnippets = (
	allSnippets: CollectionEntry<'snippets'>[],
) => {
	return allSnippets.sort((snippet1, snippet2) => {
		if (isDateBefore(snippet1.data.date, snippet2.data.date)) {
			return 1;
		}

		return -1;
	});
};

export const getAllTagsMap = (posts: CollectionEntry<'blog'>[]) => {
	const tagsMap: Record<string, number> = {};
	// Iterate through each post, putting all found tags into `tagsMap`
	for (const post of posts) {
		const postData = post.data;
		if (postData.tags && postData.tags.length > 0) {
			for (const tag of postData.tags) {
				if (tag in tagsMap) {
					tagsMap[tag] += 1;
				} else {
					tagsMap[tag] = 1;
				}
			}
		}
	}

	return tagsMap;
};

export const getSuggestedPosts = ({
	parentPost,
	allPosts,
	limit = 3,
}: {
	parentPost: CollectionEntry<'blog'>;
	allPosts: CollectionEntry<'blog'>[];
	limit?: number;
}) => {
	let allBlogs = getPublishedAndSortedPosts(allPosts);

	// Get the number of common tags with provided post.
	const getTagScore = (post: CollectionEntry<'blog'>) => {
		let commonTags = 0;
		for (const tag of post.data.tags) {
			if (parentPost.data.tags.indexOf(tag) !== -1) {
				commonTags += 1;
			}
		}
		return commonTags;
	};

	// Get the date of the post in date object.
	const getDate = (post: CollectionEntry<'blog'>) => {
		return post.data.date;
	};

	// Filter out the post for which suggested posts are to be found
	allBlogs = allBlogs.filter(
		(post: CollectionEntry<'blog'>) => post.slug !== parentPost.slug,
	);

	return allBlogs
		.sort((postA: CollectionEntry<'blog'>, postB: CollectionEntry<'blog'>) => {
			if (getTagScore(postA) === getTagScore(postB)) {
				return getDate(postB) > getDate(postA) ? 1 : -1;
			}
			return getTagScore(postB) - getTagScore(postA);
		})
		.slice(0, limit);
};

export const regexToMatchTweetURL =
	/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;

export const getFormattedTweetBody = (text = '', hasMedia = false) => {
	let formattedText = text;

	if (hasMedia) {
		const formattedTextSplit = formattedText.split(' ');

		const formattedTextWithEndingUrl = formattedTextSplit
			.slice(0, formattedTextSplit.length - 1)
			.join(' ');

		formattedText = formattedTextWithEndingUrl;
	}

	formattedText = formattedText
		.replace(regexToMatchTweetURL, (match: string) => {
			// format all hyperlinks
			return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none; word-break: break-word" class="remove-underline" href="${match}" target="_blank">${match.replace(
				/^http(s?):\/\//i,
				'',
			)}</a>`;
		})
		.replace(/\B@([\w-]+)/gim, (match: string) => {
			// format all @ mentions
			return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none; word-break: break-word" class="remove-underline" href="https://twitter.com/${match.replace(
				'@',
				'',
			)}" target="_blank">${match}</a>`;
		})
		.replace(/(#+[a-zA-Z0-9(_)]{1,})/g, (match: string) => {
			// format all # hashtags
			return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none; word-break: break-word" class="remove-underline" href="https://twitter.com/hashtag/${match.replace(
				'#',
				'',
			)}" target="_blank">${match}</a>`;
		});

	return formattedText;
};
