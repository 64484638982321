import { twMerge } from 'tailwind-merge';
import type { CollectionEntry } from 'astro:content';
import type { JSXElement } from 'solid-js';
import { createSignal, onMount } from 'solid-js';
import Card from '@components/ui/card/Card';
import CardTitle from '@components/ui/card/CardTitle';
import CardEyebrow from '@components/ui/card/CardEyebrow';
import CardDescription from '@components/ui/card/CardDescription';
import CardCta from '@components/ui/card/CardCta';
import type { PostClickedFrom } from '@lib/types';

type Props = CollectionEntry<'blog'> & {
	class?: string;
	from?: PostClickedFrom;
	fromSlug?: string;
	readingHistorySet: Set<string>;
};

export default function ArticleCard(props: Props): JSXElement {
	const [isRead, setIsRead] = createSignal(false);
	const isCanonicalPost = Boolean(props.data.canonicalUrl);
	const isPublished = props.data.published;

	onMount(() => {
		setIsRead(props.readingHistorySet.has(props.slug));
	});

	const toUrl = isCanonicalPost
		? props.data.canonicalUrl
		: `/blog/${props.slug}/`;
	const umamiEvents =
		props.from && props.from !== 'home'
			? {
					'data-umami-event': `blog-navigation~${props.from}~${props.fromSlug}`,
					'data-umami-event-slug': props.slug,
				}
			: {};

	return (
		<li class={twMerge('flex flex-col gap-16', props.class)}>
			<Card class="h-full">
				<CardEyebrow class="group-hover:text-zinc-600 dark:group-hover:text-zinc-300">
					<div class="flex gap-2 mb-4">
						{props.data.tags.map((tag) => (
							<span class="relative inline-flex items-center justify-center gap-[0.6ch] px-[0.6em] py-[0.125em] text-[13px] -tracking-micro rounded border bg-neutral-50 text-center text-neutral-600 hover:border-neutral-300 dark:border-neutral-700/50 dark:bg-neutral-800/40 dark:text-neutral-400 dark:hover:border-neutral-700 dark:hover:bg-neutral-800">
								# {tag}
							</span>
						))}
					</div>
				</CardEyebrow>
				<div class="flex-1">
					<CardTitle
						href={toUrl}
						class="group-hover:text-primary-600 dark:group-hover:text-primary-400"
						{...umamiEvents}
					>
						{props.data.title}
					</CardTitle>
					<CardDescription class="line-clamp-3 group-hover:text-zinc-800 dark:group-hover:text-zinc-200">
						{props.data.summary}
					</CardDescription>
				</div>
				<CardCta class="font-light text-zinc-400 dark:text-zinc-500 flex justify-between w-full group-hover:text-zinc-700 dark:group-hover:text-zinc-300">
					<time datetime={props.data.date.toISOString()}>
						{props.data.date.toLocaleDateString('en-us', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						})}
					</time>
					{import.meta.env.DEV && (
						<div>
							{isPublished ? (
								<span class="text-green-500">✅</span>
							) : (
								<span class="text-red-500">⚠️</span>
							)}
						</div>
					)}
					{isCanonicalPost && (
						<a
							href={props.data.canonicalUrl}
							target="_blank"
							rel="noreferrer noopener nofollow"
							class="group-hover:text-primary-600 dark:group-hover:text-primary-400"
						>
							External Post ↗
						</a>
					)}
					{isRead() && (
						<span
							class="text-sm text-green-600 dark:text-green-400"
							title="You've read this post"
						>
							Read ✅
						</span>
					)}
				</CardCta>
			</Card>
		</li>
	);
}
