import type { CollectionEntry } from 'astro:content';
import fuzzysort from 'fuzzysort';
import { Show, createSignal, createMemo, onMount } from 'solid-js';
import { debounce } from '@solid-primitives/scheduled';
import ArticleCard from './ui/ArticleCard';
import { getRandomPostTitle } from '@lib/utils';
import { READING_HISTORY_KEY } from './ReadingHistory';

const options = {
	keys: ['data.title', 'data.summary', 'slug'],
	limit: 20, // don't return more results than you need!
	threshold: -10000, // don't return bad results
};

type Props = {
	searchList: CollectionEntry<'blog'>[];
};

type ReadFilter = 'all' | 'read' | 'unread';

export default function SearchList({ searchList }: Props) {
	const [query, setQuery] = createSignal('');
	const [readFilter, setReadFilter] = createSignal<ReadFilter>('all');
	const [randomPostTitle, setRandomPostTitle] = createSignal('Search blogs...');
	const [readingHistorySet, setReadingHistorySet] = createSignal(
		new Set<string>(),
	);

	onMount(() => {
		try {
			const readingHistory = JSON.parse(
				localStorage.getItem(READING_HISTORY_KEY) || '[]',
			) as string[];

			setReadingHistorySet(new Set(readingHistory));
		} catch (err) {
			console.error('Error reading reading history', err);
		}
	});

	const filteredPosts = createMemo(() => {
		let filtered = searchList;

		// First apply search filter
		if (query() && query().trim().length > 0) {
			filtered = fuzzysort
				.go(query(), searchList, options)
				.map((result) => result.obj);
		}

		// Then apply read/unread filter
		if (readFilter() !== 'all') {
			filtered = filtered.filter((post) => {
				const isRead = readingHistorySet().has(post.slug);
				return readFilter() === 'read' ? isRead : !isRead;
			});
		}

		return filtered;
	});

	createMemo(() => {
		if (filteredPosts().length > 0 && query().trim().length === 0) {
			setRandomPostTitle(`Try "${getRandomPostTitle(filteredPosts())}"`);
		} else {
			setRandomPostTitle('Search blogs...');
		}
	});

	const debouncedSetQuery = debounce((query: string) => setQuery(query), 400);

	return (
		<div>
			<div class="flex flex-col sm:flex-row gap-4 items-start sm:items-center mb-8">
				<div class="relative flex-1 w-full">
					<label
						for="search"
						class="sr-only mb-2 text-sm font-medium text-zinc-900 dark:text-white"
					>
						Search
					</label>
					<div class="relative">
						<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
							<svg
								class="h-5 w-5 text-zinc-500 dark:text-zinc-400"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title class="sr-only">Search icon</title>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/>
							</svg>
						</div>
						<input
							class="block w-full rounded-lg bg-transparent border border-zinc-300 p-2 pl-10 text-sm text-zinc-900 focus:outline-primary-500 dark:border-zinc-600 dark:text-white dark:placeholder-zinc-400 focus:ring-transparent transition-all duration-200"
							placeholder={randomPostTitle()}
							required
							id="search"
							autocomplete="off"
							onInput={(e) => debouncedSetQuery(e.target.value)}
							value={query()}
						/>
					</div>
				</div>
				<div class="flex gap-2 items-center">
					<button
						type="button"
						onClick={() => {
							setReadFilter('all');
							try {
								window.umami?.track('read_filter', {
									filter: 'all',
								});
							} catch (error) {
								console.error('Error tracking read filter', error);
							}
						}}
						class={`px-3 py-1.5 text-sm rounded-lg transition-colors ${
							readFilter() === 'all'
								? 'bg-primary-600 dark:bg-primary-400 text-white dark:text-zinc-900'
								: 'bg-zinc-100 text-zinc-700 hover:bg-zinc-200 dark:bg-zinc-800 dark:text-zinc-300 dark:hover:bg-zinc-700'
						}`}
						title="Show all posts"
					>
						All
					</button>
					<button
						type="button"
						onClick={() => {
							setReadFilter('read');
							try {
								window.umami?.track('read_filter', {
									filter: 'read',
								});
							} catch (error) {
								console.error('Error tracking read filter', error);
							}
						}}
						class={`px-3 py-1.5 text-sm rounded-lg transition-colors ${
							readFilter() === 'read'
								? 'bg-primary-600 dark:bg-primary-400 text-white dark:text-zinc-900'
								: 'bg-zinc-100 text-zinc-700 hover:bg-zinc-200 dark:bg-zinc-800 dark:text-zinc-300 dark:hover:bg-zinc-700'
						}`}
						title="Show only read posts"
					>
						Read
					</button>
					<button
						type="button"
						onClick={() => {
							setReadFilter('unread');
							try {
								window.umami?.track('read_filter', {
									filter: 'unread',
								});
							} catch (error) {
								console.error('Error tracking read filter', error);
							}
						}}
						class={`px-3 py-1.5 text-sm rounded-lg transition-colors ${
							readFilter() === 'unread'
								? 'bg-primary-600 dark:bg-primary-400 text-white dark:text-zinc-900'
								: 'bg-zinc-100 text-zinc-700 hover:bg-zinc-200 dark:bg-zinc-800 dark:text-zinc-300 dark:hover:bg-zinc-700'
						}`}
						title="Show only unread posts"
					>
						Unread
					</button>
				</div>
			</div>

			<Show when={filteredPosts().length > 0}>
				<main class="mt-12">
					<section>
						<ul class="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3 sm:[&_>_*:not(:hover)]:hover:opacity-70">
							{filteredPosts().map((post) => (
								<ArticleCard
									{...post}
									readingHistorySet={readingHistorySet()}
								/>
							))}
						</ul>
					</section>
				</main>
			</Show>

			<Show when={filteredPosts().length === 0}>
				<div class="flex justify-center py-8">
					<p class="font-medium tracking-wide text-zinc-500 dark:text-zinc-400">
						🙅🏻‍♂️ No results found, try something else
					</p>
				</div>
			</Show>
		</div>
	);
}
